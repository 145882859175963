import { connect } from 'react-redux';
import { checkRoles, impersonateLogIn } from 'modules/auth';
import Users from '../components/Users';

const mapDispatchToProps = {
  checkRoles,
  impersonateLogIn,
};

const mapStateToProps = (state) => ({
  profile: state.auth.profile,
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
