import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import LocalizedMessage from 'components/LocalizedMessage';
import URLFiltersHoc, { Props } from 'components/URLFiltersHoc';
import Breadcrumbs from 'components/Breadcrumbs';
import FilterList from 'components/FilterList';
import TableList from 'components/TableList';

import API from 'api';

interface IState {
  filterItems: any[],
  dataList: any[],
  dataListTotal: number;
  isLoading: boolean;
}

class AnnualBudgets extends PureComponent<Props, IState> {
  state: IState = {
    filterItems: [],
    dataList: [],
    dataListTotal: 1,
    isLoading: false,
  };

  mounted = false;
  refreshListTimer = 0;
  refreshListTimerDelay = 500;

  componentDidMount () {
    this.mounted = true;
    this.loadData();
    this.refreshList();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.filters !== this.props.filters) {
      this.refreshList();
    }
  }

  componentWillUnmount () {
    this.mounted = false;
    clearTimeout(this.refreshListTimer);
  }

  async loadData () {
    const filterItems = await API.annualBudgets.getFilterItems();

    if (!this.mounted) {
      return;
    }

    this.setState({
      filterItems,
    });
  }

  refreshList () {
    clearTimeout(this.refreshListTimer);
    const { currentPage, currentSort, max, items } = this.props.filters;
    const dataListRequest = {
      page: currentPage,
      order: currentSort,
      max,
      filter: { items },
    };

    this.refreshListTimer = window.setTimeout(async () => {
      this.setState({ isLoading: true });

      const dataList = await API.annualBudgets.list(dataListRequest);

      if (!this.mounted) {
        return;
      }

      this.setState({
        dataList: dataList.items,
        dataListTotal: dataList.total,
        isLoading: false,
      });
    }, this.refreshListTimerDelay);
  }

  getBreadcrumbs () {
    return [
      {
        title: <LocalizedMessage id='home' />,
        link: '/app',
      },
      {
        title: <LocalizedMessage id='annualBudgets' />,
        link: '/app/annualBudgets',
      },
    ];
  }

  editAnnualBudget (id) {
    const { history } = this.props;

    history.push(`/app/annualBudgets/${id}`);
  }

  render () {
    const {
      filterItems,
      dataListTotal,
      dataList,
      isLoading,
    } = this.state;
    const { onUpdateSort, onUpdatePage, filters, onChangeFilterItems } = this.props;

    return (
      <>
        <LocalizedMessage
          id={[
            'site.title.annualBudgets',
            'site.title.annualBudgets.add',
          ]}
        >
          {(localizedMessage, localizedAddAnnualBudgetMessage) => (
            <>
              <Helmet
                title={localizedMessage}
              />
              <Breadcrumbs
                title={localizedMessage}
                data={this.getBreadcrumbs()}
                className='col-lg-8'
              >
                <div className='col-lg-4'>
                  <div className='title-action'>
                    <Link
                      className='btn btn-warning'
                      to='/app/annualBudgets/create'
                    >
                      <i className='fa fa-plus fa-lg' />
                      {localizedAddAnnualBudgetMessage}
                    </Link>
                  </div>
                </div>
              </Breadcrumbs>
            </>
          )}
        </LocalizedMessage>

        <div className='wrapper wrapper-content'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='ibox float-e-margins'>
                <div className='ibox-content'>
                  <div className='row'>
                    <div className='col-sm-12 m-b-sm d-flex'>
                      <div className='flex-grow-1 m-r'>
                        <FilterList
                          placeholder={<LocalizedMessage id='annualBudgets.annualBudgetsFilter.placeholder' />}
                          options={filterItems}
                          onChange={onChangeFilterItems}
                          value={filters.items}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='table-responsive'>
                    <LocalizedMessage id={['advertiser', 'period', 'budget']}>
                      {(localizedAdvertiser, localizedPeriod, localizedBudget) => (
                        <TableList
                          currentPage={filters.currentPage}
                          currentSort={filters.currentSort}
                          totalItems={dataListTotal}
                          itemsCountPerPage={filters.max}
                          onUpdateSort={onUpdateSort}
                          onUpdatePage={onUpdatePage}
                          isLoading={isLoading}
                          head={[
                            {
                              label: localizedAdvertiser,
                              sort: 'advertiser_id',
                            },
                            {
                              label: localizedPeriod,
                              sort: 'period',
                            },
                            {
                              label: localizedBudget,
                              sort: 'budget',
                            },
                          ]}
                        >
                          {
                            dataList.map(annualBudget => (
                              <tr
                                key={`${annualBudget.id}_${annualBudget.name}`}
                                onClick={() => this.editAnnualBudget(annualBudget.id)}
                                className='_cursor--pointer'
                              >
                                <td>
                                  {annualBudget.extend.advertiser.name}
                                </td>
                                <td>
                                  {annualBudget.period}
                                </td>
                                <td>
                                  {annualBudget.budget}
                                </td>
                              </tr>
                            ))
                          }
                        </TableList>
                      )}
                    </LocalizedMessage>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default URLFiltersHoc(AnnualBudgets, 'advertiser_id');
