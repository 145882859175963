import Config from 'config';
import { serialize, fetchResponse } from 'helpers/fetch';
import { capitalizeFirstLetter } from 'helpers/utils';
import store from 'store';

const getHeaders = (withToken, isJson, isMultipart) => {
  const headers = {
    ...Config.api.headers,
  };

  headers['Accept-Language'] = store.getState().locale.lang;

  if (isMultipart) {
    // headers['Content-Type'] = 'multipart/form-data';
  } else if (isJson) {
    headers.Accept = 'application/json';
    headers['Content-Type'] = 'application/json';
  } else {
    headers['Content-Type'] = 'application/x-www-form-urlencoded';
  }

  const token =
    typeof withToken === 'object' &&
    typeof withToken.type === 'string' && withToken.type.length &&
    typeof withToken.token === 'string' && withToken.token.length
      ? `${capitalizeFirstLetter(withToken.type)} ${withToken.token}`
      : localStorage.access_token ? `Bearer ${localStorage.access_token}` : null;

  if (withToken && token) {
    headers.Authorization = token;
  }

  return headers;
};

const getBody = (data, isJson, isMultipart) => {
  if (typeof data === 'function') {
    data = data();
  }

  if (isMultipart) {
    return data;
  }

  return isJson ? JSON.stringify(data) : serialize(data);
};

export default {
  get (uri, data = {}, withToken = true, options = {}, showError, initController, otherParams = {}) {
    const query = Object.keys(data).length ? `?${serialize(data)}` : '';
    const { isJson = true, isMultipart = false } = options;

    return fetchResponse((uri.charAt(0) === '/' ? Config.api.host : '') + uri + query, {
      headers: () => getHeaders(withToken, isJson, isMultipart),
      credentials: 'include',
      initController,
      ...otherParams,
    }, showError);
  },

  post (uri, data = {}, withToken = true, options = {}, showError, initController, otherParams = {}) {
    const { isJson = true, isMultipart = false } = options;

    return fetchResponse((uri.charAt(0) === '/' ? Config.api.host : '') + uri, {
      method: 'POST',
      headers: () => getHeaders(withToken, isJson, isMultipart),
      body: () => getBody(data, isJson, isMultipart),
      credentials: 'include',
      initController,
      ...otherParams,
    }, showError);
  },

  put (uri, data = {}, withToken = true, options = {}, showError, initController, otherParams = {}) {
    const { isJson = true, isMultipart = false } = options;

    return fetchResponse((uri.charAt(0) === '/' ? Config.api.host : '') + uri, {
      method: 'PUT',
      headers: () => getHeaders(withToken, isJson, isMultipart),
      body: () => getBody(data, isJson, isMultipart),
      credentials: 'include',
      initController,
      ...otherParams,
    }, showError);
  },

  delete (uri, data = {}, withToken = true, options = {}, showError, initController, otherParams = {}) {
    const { isJson = true, isMultipart = false } = options;

    return fetchResponse((uri.charAt(0) === '/' ? Config.api.host : '') + uri, {
      method: 'DELETE',
      headers: () => getHeaders(withToken, isJson, isMultipart),
      body: () => getBody(data, isJson, isMultipart),
      credentials: 'include',
      initController,
      ...otherParams,
    }, showError);
  },
};
