import React, { Component } from 'react';
import moment from 'moment';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Checkbox } from 'react-ui-icheck';
import LocalizedMessage, { localizeMessage } from 'components/LocalizedMessage';

import Select from 'components/Select';
import Breadcrumbs from 'components/Breadcrumbs';
import API from 'api';
import Alert from 'helpers/alert';
import { IOption, IProfile, MediaType } from 'types';
import { Helmet } from 'react-helmet';
import history from '../../../../history';

import classes from './User.module.scss';

interface IProps {
  currentUser: IProfile;
  checkRoles: (role: string) => boolean;
}

interface IState {
  isEditMode: boolean;
  isPageLoaded: boolean;

  user: Partial<IProfile>

  rolesOptions: IOption<string>[];
  agenciesOptions: IOption[];
  advertisersOptions: IOption[];


  userDisabled: boolean,
  userEmail: string,
  userPassword: string,
  userFirstName: string,
  userLastName: string,
  userRole: string,
  userAgency: IOption | null,
  userAdvertisers: IOption[],
  userAllowedMedias: MediaType[],
}

const mediaOptions: IOption<MediaType>[] = [
  { value: MediaType.TV, label: localizeMessage({ id: 'tv' }) },
  { value: MediaType.INTERNET, label: localizeMessage({ id: 'internet' }) },
];

class User extends Component<RouteComponentProps<{ id: string }> & IProps, IState> {
  static propTypes = {};

  state: IState = {
    rolesOptions: [],
    advertisersOptions: [],
    agenciesOptions: [],

    isEditMode: false,
    isPageLoaded: false,

    user: {
      role: { name: '', nameTranslation: '' },
    },
    userDisabled: false,
    userEmail: '',
    userPassword: '',
    userFirstName: '',
    userLastName: '',
    userRole: '',
    userAgency: null,
    userAdvertisers: [],
    userAllowedMedias: [],
  };

  mounted = false;

  _form: HTMLFormElement | null = null;

  componentDidMount () {
    this.mounted = true;

    this.loadUser();
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  async loadUser () {
    const {
      match: {
        params,
      },
      checkRoles,
    } = this.props;

    const isSuperAdmin = checkRoles('SUPER_ADMIN');

    const state: Partial<IState> = {};

    const id = params && params.id;
    const isEditMode = typeof id === 'string' && id.length > 1;

    state.isEditMode = isEditMode;

    const promises = [
      API.users.getRoles(),
      isEditMode ? API.users.get(id) : Promise.resolve(null),
      API.advertisers.list({ max: 0 }),
    ];

    if (isSuperAdmin) {
      promises.push(API.agencies.list({ max: 0 }));
    }

    try {
      const [roles, user, advertisers, agencies] = await Promise.all(promises);

      if (!this.mounted) {
        return;
      }

      state.user = user || { role: {} };

      state.rolesOptions = roles.map(role => ({
        value: role.name,
        label: role.nameTranslation,
      }));

      state.userRole = state.user?.role?.name || roles[0].name;
      state.userEmail = state.user?.mail || '';
      state.userFirstName = state.user?.firstName || '';
      state.userLastName = state.user?.lastName || '';
      state.userDisabled = state.user?.disabled;
      state.userAllowedMedias = state.user?.allowedMedias || [];

      if (agencies) {
        state.agenciesOptions = agencies.items.map(agency => ({
          value: agency.id,
          label: agency.name,
        }));

        state.userAgency = user ? (state.agenciesOptions || []).find(agency => (
          agency.value === user.agencyId
        )) : null;

        await this.handleChangeAgencySelect(state.userAgency);
      }

      if (advertisers) {
        state.advertisersOptions = advertisers.items.map(e => ({
          value: e.id,
          label: e.name,
        }));

        state.userAdvertisers =
          user
            ? user.advertiserIds.map(e => (state.advertisersOptions || []).find(a => a.value === e))
            : [];
      }

      state.isPageLoaded = true;

      this.setState(state as IState);
    } catch (error) {
      console.error(error);
    }
  }

  getBreadcrumbs () {
    return [
      {
        title: <LocalizedMessage id='home' />,
        link: '/app',
      },
      {
        title: <LocalizedMessage id='users' />,
        link: '/app/settings/users',
      },
      {
        title: <LocalizedMessage id='settings.user.user-detail' />,
      },
    ];
  }

  setFormRef = (ref) => {
    this._form = ref;
  };

  handleChangeAgencySelect = (userAgency) => {
    this.setState({
      userAgency,
      advertisersOptions: [],
    });

    if (!userAgency) {
      return Promise.resolve();
    }

    return new Promise(async resolve => {
      try {
        const advertisers = await API.advertisers.list({
          max: 0,
          agencyId: userAgency.value,
        });

        if (!this.mounted) {
          return;
        }

        const advertisersOptions = advertisers.items.map(e => ({
          value: e.id,
          label: e.name,
        }));

        this.setState({
          advertisersOptions,
        }, resolve);
      } catch (error) {
        console.error(error);
      }
    });
  };

  getSelectAgencyConfig () {
    const { userAgency, agenciesOptions } = this.state;

    return {
      isSearchable: true,
      value: userAgency,
      onChange: this.handleChangeAgencySelect,
      options: agenciesOptions,
      placeholder: <LocalizedMessage id='settings.user.form.agency.placeholder' />,
    };
  }

  handleChangeAdvertisersSelect = (userAdvertisers) => {
    this.setState({ userAdvertisers });
  };

  handleMediaSelectChange = (medias: IOption<MediaType>[]) => {
    this.setState({ userAllowedMedias: medias.map(media => media.value) });
  };

  getSelectAdvertisersConfig () {
    const { userAdvertisers, advertisersOptions } = this.state;

    return {
      isSearchable: true,
      isMulti: true,
      value: userAdvertisers,
      onChange: this.handleChangeAdvertisersSelect,
      options: advertisersOptions,
      placeholder: <LocalizedMessage id='settings.user.form.advertisers.placeholder' />,
    };
  }

  handleInputChange = (e) => {
    const value = e.target.type === 'file'
      ? e.target.files[0]
      : e.target.type === 'checkbox'
        ? e.target.checked
        : e.target.value;

    const key = e.target.name as keyof IState;

    // @ts-ignore
    this.setState({
      [key]: value,
    });
  };


  onSubmit = async (e) => {
    const { checkRoles } = this.props;

    const {
      user,
      userAdvertisers,
      userAgency,
      userFirstName,
      userLastName,
      userEmail,
      userPassword,
      userRole,
      userAllowedMedias,
      rolesOptions,
      isEditMode,
    } = this.state;

    e.preventDefault();

    if (!checkRoles('SUPER_ADMIN,ADMIN')) {
      return;
    }
    const role = rolesOptions.find(({ value }) => userRole === value);

    if (!userAllowedMedias.length && !isEditMode) {
      Alert.error(localizeMessage({ id: 'settings.user.errors.media-field-is-empty' }));

      return;
    }

    try {
      await API.users.save(user.id, {
        advertiserIds: userAdvertisers.map(a => a.value),
        agencyId: userAgency ? userAgency.value : null,
        firstName: userFirstName,
        lastName: userLastName,
        mail: userEmail,
        password: userPassword,
        role: { name: role?.value, nameTranslation: role?.label },
        allowedMedias: userAllowedMedias,
      });

      if (!user.id) {
        Alert.success(localizeMessage({ id: 'settings.user.save-successfully' }, { email: userEmail }));

        history.push('/app/settings/users');
      } else {
        Alert.success(localizeMessage({ id: 'settings.user.update-successfully' }, { email: userEmail }));
      }
    } catch (error) {
      console.error(error);

      Alert.error(localizeMessage({ id: 'settings.user.saving-error' }));
    }
  };

  render () {
    const { checkRoles } = this.props;

    const {
      user,
      isPageLoaded,
      userDisabled,
      userEmail,
      userPassword,
      userFirstName,
      userLastName,
      userRole,
      userAllowedMedias,
      advertisersOptions,
      rolesOptions,
    } = this.state;

    if (!isPageLoaded) {
      return null;
    }

    const isSuperAdmin = checkRoles('SUPER_ADMIN');
    const isUserSuperAdmin = userRole === 'SUPER_ADMIN';
    const isPlannerOrBuyer = (
      userRole === 'PLANNER' ||
      userRole === 'BUYER'
    );

    return (
      <>
        <LocalizedMessage
          id='site.title.settings.user'
        >
          {localizedMessage => (
            <>
              <Helmet
                title={(user.id ? `${userFirstName} ${userLastName} - ` : '') + localizedMessage}
              />
              <Breadcrumbs
                title={localizedMessage}
                data={this.getBreadcrumbs()}
              />
            </>
          )}
        </LocalizedMessage>

        <div className='row'>
          <div className='col-lg-12'>
            <div className='wrapper wrapper-content'>
              <div className='ibox'>
                <div className='ibox-content'>
                  <form
                    ref={this.setFormRef}
                    onSubmit={this.onSubmit}
                  >
                    <div className='form-horizontal'>
                      <div className='row'>
                        <div className='col-lg-12'>
                          <div className='m-b-md'>
                            <h2>User {user.mail}</h2>
                          </div>
                        </div>
                      </div>

                      {
                        user.id ? (
                          <>
                            <div className='form-group'>
                              <label className='col-lg-2 control-label'>
                                <LocalizedMessage id='settings.user.form.createdAt' />
                              </label>
                              <div className='col-lg-10'>
                                <div className='form-control-static'>
                                  {moment(user.createdAt).format('DD/MM/YY HH:mm')}
                                </div>
                              </div>
                            </div>

                            <div className='hr-line-dashed' />
                          </>
                        ) : null
                      }

                      {
                        user.id ? (
                          <>
                            <div className='form-group'>
                              <label className='col-lg-2 control-label'>
                                <LocalizedMessage id='settings.user.form.lastLogin' />
                              </label>
                              <div className='col-lg-10'>
                                <div className='form-control-static'>
                                  {moment(user.lastLoginAt).format('DD/MM/YY HH:mm')}
                                </div>
                              </div>
                            </div>

                            <div className='hr-line-dashed' />
                          </>
                        ) : null
                      }

                      <div className='form-group'>
                        <label className='col-lg-2 control-label'>
                          <LocalizedMessage
                            id='settings.user.form.disabled'
                          />
                        </label>
                        <div className='col-lg-10'>
                          <label className='checkbox-inline'>
                            <Checkbox
                              checkboxClass='icheckbox_square-green'
                              name='userDisabled'
                              onChange={this.handleInputChange}
                              checked={userDisabled}
                            />
                          </label>
                        </div>
                      </div>

                      <div className='hr-line-dashed' />

                      <LocalizedMessage
                        id='settings.user.form.email'
                      >
                        {localizedMessage => (
                          <div className='form-group'>
                            <label className='col-lg-2 control-label'>
                              {localizedMessage}
                            </label>
                            <div className='col-lg-10'>
                              <input
                                type='email'
                                placeholder={localizedMessage}
                                className='form-control'
                                name='userEmail'
                                onChange={this.handleInputChange}
                                value={userEmail}
                              />
                            </div>
                          </div>
                        )}
                      </LocalizedMessage>

                      <div className='hr-line-dashed' />

                      <LocalizedMessage
                        id='settings.user.form.password'
                      >
                        {localizedMessage => (
                          <div className='form-group'>
                            <label className='col-lg-2 control-label'>
                              {localizedMessage}
                            </label>
                            <div className='col-lg-10'>
                              <input
                                type='password'
                                placeholder={localizedMessage}
                                className='form-control'
                                name='userPassword'
                                onChange={this.handleInputChange}
                                value={userPassword}
                              />
                            </div>
                          </div>
                        )}
                      </LocalizedMessage>

                      <div className='hr-line-dashed' />

                      <LocalizedMessage
                        id='settings.user.form.firstName'
                      >
                        {localizedMessage => (
                          <div className='form-group'>
                            <label className='col-lg-2 control-label'>
                              {localizedMessage}
                            </label>
                            <div className='col-lg-10'>
                              <input
                                type='text'
                                placeholder={localizedMessage}
                                className='form-control'
                                name='userFirstName'
                                onChange={this.handleInputChange}
                                value={userFirstName}
                              />
                            </div>
                          </div>
                        )}
                      </LocalizedMessage>

                      <div className='hr-line-dashed' />

                      <LocalizedMessage
                        id='settings.user.form.lastName'
                      >
                        {localizedMessage => (
                          <div className='form-group'>
                            <label className='col-lg-2 control-label'>
                              {localizedMessage}
                            </label>
                            <div className='col-lg-10'>
                              <input
                                type='text'
                                placeholder={localizedMessage}
                                className='form-control'
                                name='userLastName'
                                onChange={this.handleInputChange}
                                value={userLastName}
                              />
                            </div>
                          </div>
                        )}
                      </LocalizedMessage>

                      <div className='hr-line-dashed' />

                      <div className='form-group'>
                        <label className='col-lg-2 control-label'>
                          <LocalizedMessage
                            id='settings.user.form.role'
                          />
                        </label>
                        <div className='col-lg-10'>
                          {
                            rolesOptions.map((role, roleIndex) => (
                              <div
                                key={`${role.value}_${roleIndex}`}
                                className='i-checks'
                              >
                                <label className={classes.RoleLabel}>
                                  <input
                                    type='radio'
                                    name='userRole'
                                    checked={userRole === role.value}
                                    value={role.value}
                                    onChange={this.handleInputChange}
                                  />
                                  {' '}
                                  {role.label}
                                </label>
                              </div>
                            ))
                          }
                        </div>
                      </div>

                      <div className='hr-line-dashed' />

                      <div className='form-group'>
                        <label className='col-lg-2 control-label' htmlFor='media-type'>
                          <LocalizedMessage id='media' />
                        </label>
                        <div className='col-lg-10'>
                          <Select
                            inputId='media-type'
                            isMulti
                            value={mediaOptions.filter(({ value }) => userAllowedMedias.includes(value))}
                            options={mediaOptions}
                            onChange={this.handleMediaSelectChange}
                            placeholder={<LocalizedMessage id='settings.user.form.media.placeholder' />}
                          />
                        </div>
                      </div>

                      {
                        (
                          isSuperAdmin &&
                          !isUserSuperAdmin
                        )
                          ? (
                            <>
                              <div className='hr-line-dashed' />

                              <div className='form-group'>
                                <label className='col-lg-2 control-label' htmlFor='agencyId'>
                                  <LocalizedMessage
                                    id='settings.user.form.agency'
                                  />
                                </label>

                                <div className='col-lg-10'>
                                  <Select {...this.getSelectAgencyConfig()} />
                                </div>
                              </div>
                            </>
                          )
                          : null
                      }

                      {
                        (
                          isPlannerOrBuyer &&
                          advertisersOptions &&
                          advertisersOptions.length
                        )
                          ? (
                            <>
                              <div className='hr-line-dashed' />

                              <div className='form-group'>
                                <label className='col-lg-2 control-label' htmlFor='advertiserId'>
                                  <LocalizedMessage id='settings.user.form.advertisers' />
                                </label>

                                <div className='col-lg-10'>
                                  <Select {...this.getSelectAdvertisersConfig()} />
                                </div>
                              </div>
                            </>
                          )
                          : null
                      }

                      <div className='hr-line-dashed' />

                      <div className='form-group'>
                        <div className='col-lg-2' />
                        <div className='col-lg-10'>
                          <Link
                            to='/app/settings/users'
                            className='btn btn-white'
                          >
                            <span><LocalizedMessage id='cancel' /></span>
                          </Link>
                          &nbsp;&nbsp;
                          <button
                            type='submit'
                            className='btn btn-primary'
                            onClick={this.onSubmit}
                          >
                            <i className='fa fa-chevron-down fa-lg' />
                            <span><LocalizedMessage id='save' /></span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default User;
