import FilterItems, { IProcessedFilterItem } from 'helpers/filterItems';
import Client from 'api/release/HTTPClient';
import { ICommitment, IGenericListRequest, IServerPage } from 'types';

const commitmentsAPI = {
  getForAdvertiserPeriod (request): Promise<IServerPage<ICommitment>> {
    return Client.post('/api/commitments/getForClientPeriod', request);
  },

  list (request: IGenericListRequest): Promise<IServerPage<ICommitment>> {
    return Client.post('/api/commitments/', request);
  },

  get (id: string | number): Promise<ICommitment> {
    return Client.get(`/api/commitments/${id}`);
  },

  save (id: number, data): Promise<ICommitment> {
    if (!id) {
      return Client.post('/api/commitments/new', data);
    }

    return Client.put(`/api/commitments/${id}`, data);
  },

  async getFilterItems (request): Promise<IProcessedFilterItem[]> {
    const result = await Client.get('/api/commitments/filter-items', request);

    return FilterItems.getDataAndPostProcess(result);
  }
};

export default commitmentsAPI;
