import FilterItems, { IProcessedFilterItem } from 'helpers/filterItems';
import Client from 'api/release/HTTPClient';
import {
  IAnnualPlan,
  IDataRangeRetro,
  IFilterItem,
  IFrontendAnnualPlan,
  IOptimizerStatusResponse,
  IServerPage,
  PalomarsStatus,
  MediatorRequestData,
  IPerformanceCalculationType,
  IPerformanceResultStatus
} from 'types';
import AnnualPlan from 'routes/AnnualPlan';

const annualPlansAPI = {
  list (request): Promise<IServerPage<IAnnualPlan>> {
    return Client.post('/api/annualPlans/', request);
  },

  get (id: string): Promise<IFrontendAnnualPlan> {
    return Client.get(`/api/annualPlans/${id}`);
  },

  getWithCalculations (id): Promise<AnnualPlan> {
    return Client.get(`/api/annualPlans/${id}`, {
      show: true
    });
  },

  // not used
  getLatestVersion (id) {
    return Client.get(`/api/annualPlans/${id}/version`);
  },

  getByIdAndVersion (id, version): Promise<AnnualPlan> {
    return Client.get(`/api/annualPlans/${id}/versions/${version}`);
  },

  // not used
  getTrpBudgetRate () {
    return Client.get('/api/annualPlans/getTrpBudgetRate/');
  },

  getRetroDiffs (date: string): Promise<IDataRangeRetro> {
    return Client.get(`/api/retro/${date}/`);
  },

  copy (id: string): Promise<AnnualPlan> {
    return Client.get(`/api/annualPlans/${id}/copy/`);
  },

  stop (id: string): Promise<void> {
    return Client.post(`/api/annualPlans/${id}/stop`);
  },

  delete (id: string): Promise<void> {
    return Client.delete(`/api/annualPlans/${id}/`);
  },

  async getFilterItems (request): Promise<IProcessedFilterItem[]> {
    const result: IFilterItem[] = await Client.get(
      '/api/annualPlans/filter-items',
      request
    );

    return FilterItems.getDataAndPostProcess(result);
  },

  saveWithoutCalculations (data): Promise<IOptimizerStatusResponse> {
    return Client.post('/api/annualPlans/newWithoutCalculation', data);
  },

  save (id, data): Promise<IOptimizerStatusResponse> {
    if (!id) {
      return Client.post('/api/annualPlans/new', data);
    }

    return Client.put(`/api/annualPlans/${id}`, data);
  },

  uploadCampaigns (data): Promise<Record<string, any>> {
    return Client.post('/api/annualPlans/campaigns/upload/', data, true, {
      isJson: false,
      isMultipart: true
    });
  },

  uploadDealRestrictions (data): Promise<Record<string, any>> {
    return Client.post(
      '/api/annualPlans/dealRestrictions/upload/',
      data,
      true,
      { isJson: false, isMultipart: true }
    );
  },

  uploadCampaignRestrictions (data): Promise<Record<string, any>> {
    return Client.post(
      '/api/annualPlans/campaignRestrictions/upload/',
      data,
      true,
      {
        isJson: false,
        isMultipart: true
      }
    );
  },

  exportDealBriefTable (id): Promise<string> {
    return Client.get(
      `/api/annualPlans/${id}/exportDealBriefTable/`,
      {},
      true,
      {
        isJson: false
      }
    );
  },

  exportProgrammingTable (id, withoutZeroQFields): Promise<string> {
    return Client.get(
      `/api/annualPlans/${id}/exportProgrammingTable/`,
      { withoutZeroQFields },
      true,
      {
        isJson: false
      }
    );
  },

  exportCoverBuildUpTable (id): Promise<any> {
    return Client.get(
      `/api/annualPlans/${id}/exportCoverBuildUpTable/`,
      {},
      true,
      {
        isJson: false
      }
    );
  },

  exportPalomarsReport (id: string): Promise<any> {
    return Client.get(`/api/annualPlans/palomars/excel/${id}`);
  },

  getPalomarsStatus (id): Promise<PalomarsStatus> {
    return Client.get(`/api/annualPlans/palomars/status/${id}`);
  },

  // not used
  getItemDetails (id) {
    return Client.get(`/api/mediaplans/items/${id}/details/`);
  },

  // not used
  saveItemDetails (data) {
    return Client.put('/api/mediaplans/items/details/', data);
  },

  json (id: string): Promise<any> {
    return Client.get(`/api/annualPlans/${id}/json/`, {}, true, {
      isJson: false
    });
  },

  plots (id, plotType) {
    return Client.get(`/api/annualPlans/plots/${id}/${plotType}/result`);
  },

  exportMediatorsExcel (data: MediatorRequestData) {
    return Client.post('/api/annualPlans/perf/defaultMediatorsExcel', data);
  },

  uploadMediatorsData (id: string, formData: FormData) {
    return Client.put(
      `/api/annualPlans/perf/${id}/userMediators`,
      formData,
      true,
      {
        isJson: false,
        isMultipart: true
      }
    );
  },

  exportPrevMediatorsData (id: string) {
    return Client.get(
      `/api/annualPlans/perf/${id}/mediatorsAppliedForCalculationExcel`
    );
  },

  resetMediatorsData (id: string) {
    return Client.delete(`/api/annualPlans/perf/${id}/resetData`);
  },

  tradeWhiteListResult (code: string) {
    return Client.get(
      `/api/annualPlans/performance/trade/excel/${code}/result`
    );
  },

  tradeWhiteListRaw (code: string) {
    return Client.get(`/api/annualPlans/performance/trade/excel/${code}/raw`);
  },

  calculateTradeWhiteList (code: string, type: IPerformanceCalculationType) {
    return Client.post(
      `/api/annualPlans/performance/trade/excel/${code}/calculate`,
      type
    );
  },
  performanceStatus (code: string): Promise<IPerformanceResultStatus> {
    return Client.get(
      `/api/annualPlans/performance/trade/status/${code}`,
      {},
      true,
      {},
      true,
      null,
      { withoutLoader: true }
    );
  },

  palomarsRecalculate (
    code: string,
    palomarsRetroFrom: string,
    palomarsRetroTo: string
  ) {
    return Client.post(
      `/api/annualPlans/palomars/excel/${code}/calculate`,
      {
        palomarsRetroFrom,
        palomarsRetroTo
      },
      true,
      { isJson: false }
    );
  }
};

export default annualPlansAPI;
