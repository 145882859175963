import { ChannelType } from 'routes/AnnualPlanEdit/types';

export * from './typeGuards';

export type Locale = 'ru' | 'en';

/**
 * Responses
 */

export type IPerformanceResultStatus =
  | 'NOT_CALCULATED'
  | 'RECEIVED'
  | 'IN_QUEUE'
  | 'STARTED'
  | 'SUCCESS'
  | 'ERROR'
  | 'FAILURE'
  | 'REVOKED';

export interface IdName {
  id?: number;
  name?: string;
}

export interface IProfile {
  firstName: string;
  lastName: string;
  id: number;
  agencyId: number;
  mail: string;
  role: { name: string; nameTranslation: string };
  createdAt: string;
  lastLoginAt: string;
  advertiserIds: number[];
  extend: { agency: any };
  impersonate: true;
  disabled: boolean;
  spaceName: string;
  allowedMedias: MediaType[];
  allowedMediaStatus: AllowedMediaStatus;
}

export type AllowedMediaStatus = 'tv' | 'internet' | 'all' | 'none';

export interface IAgency {
  id?: number;
  name?: string;
}

export interface IBackendChannel {
  id: number;
  name: string;
  sortWeight: number;
  type: ChannelType;
  inventoryType: ChannelInventoryType;
}

export type ChannelInventoryType = 'GRP' | 'MIN';

export interface IChannelMonthBudget {
  channel: IBackendChannel;
  fixOffPrimeSharePercent: number;
  fixPrimeSharePercent: number;
  fixSharePercent: number;
  floatOffPrimeSharePercent: number;
  floatPrimeSharePercent: number;
  floatSharePercent: number;
  isSFix: boolean;

  total: number;
  totalTrp: number;
  totalGrp30: number;
  totalMonthsFloatOffPrime: number;
  totalMonthsFloatPrime: number;
  totalMonthsFixOffPrime: number;
  totalMonthsFixPrime: number;

  months: number[];

  monthsFloat: number[];
  monthsFloatPrime: number[];
  monthsFloatPrimeShare: number[];
  monthsFloatShare: number[];
  monthsFloatOffPrime: number[];
  monthsFloatOffPrimeShare: number[];

  monthsFix: number[];
  monthsFixPrime: number[];
  monthsFixPrimeShare: number[];
  monthsFixShare: number[];
  monthsFixOffPrime: number[];
  monthsFixOffPrimeShare: number[];

  monthsGrp30: number[];
  monthsTrp: number[];
}

interface FixCalculationPrimeType {
  name: string;
  trp: number;
  grp: number;
  budget: number;
  price: number;
  q: number;
  sfixShare: number;

  programCategory: string;
  weekend: boolean;
  from: string;
  to: string;
}

interface PriceChannelRestrictionDTO {
  seasons: number[];
  additionalDiscount: number;
  fixPrimeFactor: number;
  fixOffprimeFactor: number;
  floatPrimeFactor: number;
  floatOffprimeFactor: number;
  isSFix: boolean;
  baseCPP: number;
}
interface FloatCalculationPrimeType {
  name: string;
  trp: number;
  grp: number;
  budget: number;
  price: number;
  q: number;
}
interface CalculationPeriod {
  from: string;
  to: string;
  trp: number;
  budget: number;
  floatOffPrime: FloatCalculationPrimeType;
  floatPrime: FloatCalculationPrimeType;
  fixPrime: FixCalculationPrimeType[];
  fixOffPrime: FixCalculationPrimeType[];
  price: PriceChannelRestrictionDTO;
  channel: IBackendChannel;
  q: number;
  grp: number;
}

interface CalculationReachAll {
  n: number;
  reach: number;
  reachPercent: number;
}

interface CalculationAddPlatformPeriod {
  from: string;
  to: string;
  trp: number;
  budget: number;
  platform: { name: string; type: string };
  impression: number;
  reach: number;
  reachPlus: number;
  reachAll: CalculationReachAll[];
  universe: number;
}

interface CalculationAddPlatform {
  budget: number;
  trp: number;
  periods: CalculationAddPlatformPeriod[];
  reach: number;
  reachPercent: number;
  frequency: string;
  closed: boolean;
  reachAll: CalculationReachAll[];
  universe: number;
  coverBuildUp: Record<string, any>;
}

interface CalculationRestricted {
  q: number;
  trp: number;
  grp: number;
  budget: number;
  periods: CalculationPeriod[];
  reach: number;
  reachPlus: any;
  reachPercent: number;
  frequency: string;
  closed: boolean;
  reachAll: CalculationReachAll[];
  universe: number;
  coverBuildUp: Record<string, any>;
}

enum MediaPlatform {
  TV= 'TV',
  OLV = 'OLV',
  ALL = 'ALL'
}

interface Calculation {
  restricted: CalculationRestricted;
  addPlatforms: CalculationAddPlatform | null;

  reach: number;
  reachPercent: number;
  conversion: number;
  frequency: string;
  closed: boolean;
  reachAll: CalculationReachAll[];
  universe: number;

  coverBuildUp: Record<string, any>;
}

interface PlotSettings {
  enable: boolean;
  calculationBase: string;
  min: number;
  max: number;
  step: number;
  freqs: number[];
}

export interface IPlotsInfo {
  tvOlvPlot: PlotSettings;
  cbuPlot: PlotSettings;
  turfPlot: PlotSettings;
}

interface CampaignCommonData {
  retroFrom: string;
  retroTo: string;
  universe: number;
  reachAll: CalculationReachAll[];
}

export interface OlvFrontendRepresentation {
  budget: number;

  customColumns?: Record<string, string>

  impression: number;

  trp: number;

  reachAll: CalculationReachAll[];
}

export interface OlvFrontendDataHolder {
  platforms: Record<string, OlvFrontendRepresentation>;
  total: OlvFrontendRepresentation;
}

interface CalculationWarningChannel {
  id: string;
  name: string;
  type: string;
}

export type CalculationWarnings = Record<string,
{
  channel: CalculationWarningChannel;
  quality: string;
  warning: string;
}[]>;

export interface IChannelWarning {
  channel: {
    id: number;
    name: string;
    type: ChannelType;
  };
  qualityRestrictions: {
    fixPercentExp?: { min: number; max: number };
    fixPercentObs?: number;
    fixPrimeExp?: { min: number; max: number };
    fixPrimeObs?: number;
    floatPrimeExp?: { min: number; max: number };
    floatPrimeObs?: number;
    reason: string[]
  };
}
interface RestrictionWarnings {
  channelWarnings: IChannelWarning[];
  // groupWarnings: GroupWarning[] ;
  groupWarnings: any[]; // todo
}

export interface OutMediaplanRepresentation {

  additionalTAStats: Record<number, {
    name: string;
    campaignCommonData: Record<MediaPlatform, CampaignCommonData>;
    calculations: {
      calculations: Calculation;
      restrictionWarnings: RestrictionWarnings;
      targetAudience: IdName;
    }
  }> | null;
  name: string;
  brand: null | IdName;
  budget: number;
  tvBudgetPercent: number;
  internetBudgetPercent: number;
  planningPercent: number;

  retroFrom: string;

  retroTo: string;

  from: string;

  to: string;

  olvRetroFrom: string;

  olvRetroTo: string;
  targetAudience: IdName;

  calculations: Calculation;
  plots: IPlotsInfo;
  campaignCommonData: Record<MediaPlatform, CampaignCommonData>;
  olvCalculated: OlvFrontendDataHolder;
  movieDuration: number;
  warnings: CalculationWarnings;
  restrictionWarnings: RestrictionWarnings;
  isConverseOptimization?: boolean;
}

export interface IFrontendAnnualPlan {


  // todo remove

  isTradeBusied: boolean;
  isTradeWhiteList: boolean;
  isAdvertiserCounted: boolean;

  performanceCalculationType?: IPerformanceCalculationType;

  additionalTaChannelMonthBudgets: Record<string, IChannelMonthBudget[]>;
  additionalTAMonthTVBudgets: Record<string, number[]>;
  additionalTAMonthTVTRPs: Record<string, number[]>;


  additionalTAMonthInternetTRPs: Record<string, number[]>;
  additionalTAMonthInternetBudgets: Record<string, number[]>;

  additionalTaOlvCalculated?: Record<string, OlvFrontendDataHolder>


  advertiser: { id: number; name: string };
  channelMonthsBudgets: IChannelMonthBudget[];
  channelMonthsWeeks: {
    channel: never | null;
    weeks: boolean[];
    weeksTrp: number[];
  };
  code: string;
  hasCoverBuildUp: boolean;
  internetBudget: number;
  internetTrp: number;
  isCalculatePalomarsReach: boolean;
  mediaBudgets: never | null;
  monthsBudgets: number[];
  monthsInternetBudgets: number[];
  monthsInternetTRPs: number[];
  monthsTVBudgets: number[];
  monthsTVTRPs: number[];
  name: string;
  tvBudget: number;
  tvGrp30: number;
  tvTrp: number;
  type: string | null;
  mediaplans: OutMediaplanRepresentation[];

  targetAudiencesMonthsBudgets: TargetAudiencesMonthsBudgetsRepresentation[];
  programming: IProgrammingRepresentation[];
}

export interface TargetAudience {
  id: number;
  name: string;
}

interface TargetAudiencesMonthsBudgetsRepresentation {
  months: number[];
  name: string;
}

interface IChannelProgrammingDTO {
  id: number;
  name: string;
  type: ChannelType;
  sortWeight: number;
}

interface IProgrammingRepresentation {
  q: number;

  'prime/off-prime': {
    name: string;
    nameTranslation: string;
  };

  week: string;

  trp: number;

  grp: number;

  programCategory: string;

  price: number;

  channel: IChannelProgrammingDTO;

  from: string;

  to: string;

  'fix/float': {
    name: string;
    nameTranslation: string;
  };

  isSFix: boolean;

  budget: number;

  sfixShare: number;

  movieDuration: number;
}

export interface IMediaplanTemplate {
  advertiserId?: number;
  id?: number;
  templateName?: string;
  type?: {
    name:
    | 'YEAR'
    | 'INDIVIDUAL'
    | 'CAMPAIGNS_IMP'
    | 'DEAL_RES_IMP'
    | 'CHANNEL_RES_IMP';
    nameTranslation: string;
  };
}
export interface IAdvertiser {
  agencyId?: number;
  extend?: { [key: string]: any };
  id?: number;
  name?: string;
}

export interface IAdFormat {
  id?: number;
  name?: string;
}

export interface IAdPlatform {
  averageFrequency?: number;
  cookieRealUserConversion?: number;
  cpm?: number;
  createdBy?: 'SYSTEM' | 'USER';
  id?: number;
  max?: number;
  maxReachPercent?: number;
  min?: number;
  name?: string;
  platformType?: 'OLV';
  quality?: number;
  shareOfCities_100Plus?: number;
  targetHit?: number;
}

export interface IFilterItem {
  id?: string;
  label?: { [key: string]: any };
  type?:
  | 'name'
  | 'user'
  | 'mediaplan'
  | 'mediaplanStatus'
  | 'advertiser'
  | 'period'
  | 'filterMonth'
  | 'role'
  | 'site'
  | 'adFormat'
  | 'adUnit'
  | 'annualPlan'
  | 'annualPlanStatus'
  | 'annualPlanType'
  | 'brand'
  | 'channel'
  | 'channelType'
  | 'annualPlanAuthor'
  | 'flowchart'
  | 'division'
  | 'io'
  | 'flowchartEditor'
  | 'flowchartStatus'
  | 'flowchartYear'
  | 'report'
  | 'reportAuthor'
  | 'reportYear'
  | 'adPlatform';
  typeTranslation?: string;
}
export interface IServerPage<T> {
  items: T[];
  total: number;
}

export interface IOptimizerRequestAlg {
  accurateReach?: number;
  iterations?: number;
  logAllSteps?: number;
  population?: number;
  step?: number;
  tvBuyingEnabled?: boolean;
}

export interface IOption<T = number> {
  label: string;
  value: T;
}
export interface ICampaign {
  annualPlanId?: number;
  dataJson?: string;
  externalId?: string;
  id?: number;
  name?: string;
  taskId?: number;
}

export interface IAnnualBudget {
  advertiserId?: number;
  agencyId?: number;
  budget?: number;
  extend?: { [key: string]: any };
  id?: number;
  period?: number;
}
export interface IAnnualPlan {
  advertiser?: IdName;
  advertiserId?: number;
  agencyId?: number;
  annualPeriod?: number;
  author?: IdName;
  authorId?: number;
  calculatedAt?: string;
  calculationLastStatusCheck?: string;
  calculationStart?: string;
  calculationStatusDescription?: string;
  calculationsJson?: string;
  campaigns?: ICampaign[];
  code?: string;
  dataJson?: string;
  hasJsonForOptimizer?: boolean;
  id?: number;
  isCalculatePalomarsReach?: boolean;
  isFilled?: boolean;
  jsonForOptimizer?: string;
  lastModified?: string;
  name?: string;
  optimizerVersion?: string;
  palomarsRetroFrom?: string;
  palomarsRetroTo?: string;
  palomarsStatus?:
  | 'NOT_PALOMARS'
  | 'IN_QUEUE'
  | 'STARTED'
  | 'SUCCESS'
  | 'ERROR'
  | 'FAILURE'
  | 'REVOKED';
  isCustomUniverse?: boolean;
  customUniverseValue?: number;
  requestAlg?: IOptimizerRequestAlg;
  s3FileId?: string;
  status?:
  | 'DRAFT'
  | 'IN_QUEUE'
  | 'IN_PROGRESS'
  | 'COMPLETE'
  | 'ON_REVIEW'
  | 'ON_APPROVAL'
  | 'APPROVED'
  | 'STARTED'
  | 'ERROR';
  type?: 'TV' | 'MULTIMEDIA';
  version?: number;
}

export interface IDataRangeRetro {
  from?: number;
  to?: number;
}

export interface IOptimizerStatusResponse {
  annualPlanCode?: string;
  code?: number;
  message?: string;
}

export type PalomarsStatus =
  | 'NOT_PALOMARS'
  | 'IN_QUEUE'
  | 'STARTED'
  | 'SUCCESS'
  | 'ERROR'
  | 'FAILURE'
  | 'REVOKED';

export interface IRestResponse {
  errors?: {
    errors: Record<string, any>;
  };
  ok?: boolean;
  response?: { [key: string]: any };
}

export interface IUserRepresentation {
  advertiserIds?: number[];
  agencyId?: number;
  createdAt?: string;
  disabled?: boolean;
  extend?: {
    agency?: IAgency;
  };
  firstName?: string;
  id?: number;
  lastLoginAt?: string;
  lastName?: string;
  mail?: string;
  role?: 'SUPER_ADMIN' | 'ADMIN' | 'PLANNER' | 'BUYER';
  allowedMedias: MediaType[];
}


export type IPerformanceCalculationType = 'QUALITY_PROPORTION' | 'FIX_PRIORITIZE';
export interface IBrand {
  externalId: number;
  id: number;
  name: string;
}

export interface ICalculationTemplate {
  advertiserId?: number;
  createdAt?: string;
  dataJson?: string;
  id?: number;
  name?: string;
}

export interface ICampaignPriceTemplate {
  advertiserId?: number;
  createdAt?: string;
  dataJson?: string;
  id?: number;
  name?: string;
}

export type IChannelType =
  | 'FEDERAL'
  | 'ORBITAL'
  | 'REGIONAL'
  | 'LOCAL'
  | 'CABLE'
  | 'ALL';

export interface INaturalDelivery {
  id?: number;
  name?: string;
  natural_split?: number;
}

export interface ICommitment {
  adFormatId?: number;
  adUnit?: string;
  advertiserId?: number;
  agencyId?: number;
  commitment?: number;
  extend?: { [key: string]: any };
  id?: number;
  period?: number;
  siteId?: number;
}

export interface ICommunicationChannel {
  adFormatIds?: number[];
  id?: number;
  name?: string;
}
export interface IKPI {
  goalId?: number;
  id?: number;
  name?: string;
  unit?: 'NUMBER' | 'PERCENT' | 'BOOLEAN';
}

export interface IGoal {
  id?: number;
  kpi?: IKPI[];
  name?: string;
}

export interface IRegion {
  id?: number;
  name?: string;
  rgn?: number;
}

export interface ISellerRestriction {
  channelId?: number;
  channelPlacementType?: 'FLOAT' | 'FIX' | 'SUPERFIX';
  id?: number;
  isPrime?: boolean;
  max?: number;
  min?: number;
  year?: number;
}

export interface ISite {
  externalId?: number;
  id?: number;
  name?: string;
  supportedTargetingTypes?: string;
  uniqueVisitorsPerMonth?: number;
  url?: string;
  visitorsPerMonth?: number;
}

export interface IStrategicMediaplanningRepresentation {
  advertiserId?: number;
  calculatedBudgets?: { [key: string]: number };
  campaigns?: Record<string, any>;
  monthTotalBudgets?: number[];
  period?: number;
  sitesTotalBudgets?: { key: number; value: any }[];
}

export type IDictionary = {
  name:
  | 'TARGET'
  | 'AGENCIES'
  | 'AD_FORMATS'
  | 'ADVERTISERS'
  | 'UNIT_TYPES'
  | 'COMMUNICATION_CHANNELS'
  | 'ADVERTISER_AGENCY'
  | 'SITES'
  | 'BRANDS'
  | 'INTERNET_PRICE_STATUSES'
  | 'INTERNET_PRICES'
  | 'INTERNET_PRICE_SEASON_COEFFICIENTS'
  | 'INTERNET_PRICE_TO_TARGET'
  | 'BC_DATA'
  | 'BC_DATA_AD_FORMAT'
  | 'TARGET_AUDIENCE';
  nameTranslation: string;
};

export interface ITargetAudience {
  active?: boolean;
  groupId?: number;
  groupName?: string;
  id?: number;
  name?: string;
}

export interface ITargeting {
  id?: number;
  name?: string;
}

export interface ITvCppWithBaseCoefficients {
  baseCppWithouDiscounts?: number;
  channelId?: number;
  cpp?: number;
  dateMonth?: string;
  distribution?: string;
  externalId?: number;
  id?: string;
  positionCoefficient?: number;
  positionType?:
  | 'FLOAT_OFF_PRIME'
  | 'FLOAT_PRIME'
  | 'FIX_OFF_PRIME'
  | 'FIX_PRIME';
  seasonRate?: number;
}

export interface IUnitType {
  dataType?: string;
  id?: number;
  name?: string;
}

export type IUserRole = 'SUPER_ADMIN' | 'ADMIN' | 'PLANNER' | 'BUYER';

/**
 * Requests
 */
interface FieldWithOrder {
  field?: string;
  order?: string;
}

export interface IGenericListRequest {
  filter?:CommonFilterBody;
  fieldWithOrderList?: FieldWithOrder[];
  itemsPerPage?: number;
  max?: number;
  noOffsetsAndLimits?: boolean;
  order?: FieldWithOrder;
  page?: number;
  skipTotal?: boolean;
}

export interface AdPlatformUpdateCreateRequest {
  name?: string;
  platformType?: 'OLV';
}


interface UniqueId {
  id?: string;
  type?:
  | 'name'
  | 'user'
  | 'mediaplan'
  | 'mediaplanStatus'
  | 'advertiser'
  | 'period'
  | 'filterMonth'
  | 'role'
  | 'site'
  | 'adFormat'
  | 'adUnit'
  | 'annualPlan'
  | 'annualPlanStatus'
  | 'annualPlanType'
  | 'brand'
  | 'channel'
  | 'channelType'
  | 'annualPlanAuthor'
  | 'flowchart'
  | 'division'
  | 'io'
  | 'flowchartEditor'
  | 'flowchartStatus'
  | 'flowchartYear'
  | 'report'
  | 'reportAuthor'
  | 'reportYear'
  | 'adPlatform';
}
type CommonFilterBody = {
  items?: UniqueId[];
};


export interface AnnualBudgetUpdateRequest {
  advertiserId?: number;
  budget?: number;
  id?: number;
  period?: number;
}

export interface ChannelData {
  id: number;
  name: string;
  type: ChannelType;
}

export interface MediatorRequestData {
  mediaType: 'FEDERAL' | 'LOCAL';
  retroFrom: string;
  retroTo: string;
  channels: ChannelData[];
}

export enum MediaType {
  TV = 'TV',
  INTERNET = 'INTERNET',
}
