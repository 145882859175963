import FilterItems, { IProcessedFilterItem } from 'helpers/filterItems';
import Client from 'api/release/HTTPClient';
import { IBrand, IFilterItem, IGenericListRequest, IServerPage } from 'types';

const brandsAPI = {
  list (request: IGenericListRequest): Promise<IServerPage<IBrand>> {
    return Client.post('/api/brands/', request);
  },

  listByAdvertiser (advertiserId: number, request):Promise<IServerPage<IBrand>> {
    return Client.get(`/api/brands/${advertiserId}/advertiser`, request);
  },

  get (id: number): Promise<IBrand> {
    return Client.get(`/api/brands/${id}`);
  },

  async getFilterItems (request): Promise<IProcessedFilterItem[]> {
    const result: IFilterItem[] = await Client.get('/api/brands/filter-items', request);

    return FilterItems.getDataAndPostProcess(result);
  },
};

export default brandsAPI;
