import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import LocalizedMessage from 'components/LocalizedMessage';
import URLFiltersHoc, { Props } from 'components/URLFiltersHoc';
import Breadcrumbs from 'components/Breadcrumbs';
import FilterList from 'components/FilterList';
import TableList from 'components/TableList';

import API from 'api';

interface IState {
  filterItems: any[],
  dataList: any[],
  dataListTotal: number;
  isLoading: boolean;
}

const defaultSort = 'id';

class Brands extends PureComponent<Props, IState> {
  state: IState = {
    filterItems: [],
    dataList: [],
    dataListTotal: 1,
    isLoading: false,
  };

  mounted = false;
  refreshListTimer: any = 0;
  refreshListTimerDelay = 500;

  componentDidMount () {
    this.mounted = true;
    this.loadData();
    this.refreshList();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.filters !== this.props.filters) {
      this.refreshList();
    }
  }

  componentWillUnmount () {
    this.mounted = false;
    clearTimeout(this.refreshListTimer);
  }

  async loadData () {
    const filterItems = await API.brands.getFilterItems();

    if (!this.mounted) {
      return;
    }

    this.setState({
      filterItems,
    });
  }

  refreshList () {
    clearTimeout(this.refreshListTimer);
    const { currentPage, currentSort, max, items } = this.props.filters;
    const dataListRequest = {
      page: currentPage,
      order: currentSort,
      max,
      filter: { items },
    };

    this.refreshListTimer = setTimeout(async () => {
      this.setState({ isLoading: true });

      const dataList = await API.brands.list(dataListRequest);

      if (!this.mounted) {
        return;
      }

      this.setState({
        dataList: dataList.items,
        dataListTotal: dataList.total,
        isLoading: false,
      });
    }, this.refreshListTimerDelay);
  }

  getBreadcrumbs () {
    return [
      {
        title: <LocalizedMessage id='home' />,
        link: '/app',
      },
      {
        title: <LocalizedMessage id='brands' />,
        link: '/app/dictionaries/brands',
      },
    ];
  }

  render () {
    const {
      filterItems,
      dataListTotal,
      dataList,
      isLoading,
    } = this.state;
    const { onUpdateSort, onUpdatePage, filters, onChangeFilterItems } = this.props;

    return (
      <>
        <LocalizedMessage
          id='site.title.dictionaries.brands'
        >
          {localizedMessage => (
            <>
              <Helmet
                title={localizedMessage}
              />
              <Breadcrumbs
                title={localizedMessage}
                data={this.getBreadcrumbs()}
              />
            </>
          )}
        </LocalizedMessage>

        <div className='wrapper wrapper-content'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='ibox float-e-margins'>
                <div className='ibox-content'>
                  <div className='row'>
                    <div className='col-sm-12 m-b-sm d-flex'>
                      <div className='flex-grow-1 m-r'>
                        <FilterList
                          placeholder={<LocalizedMessage id='brands-dictionary.brandsFilter.placeholder' />}
                          options={filterItems}
                          onChange={onChangeFilterItems}
                          value={filters.items}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='table-responsive'>
                    <LocalizedMessage id={['id', 'name']}>
                      {(localizedId, localizedName) => (
                        <TableList
                          currentPage={filters.currentPage}
                          currentSort={filters.currentSort}
                          totalItems={dataListTotal}
                          itemsCountPerPage={filters.max}
                          onUpdateSort={onUpdateSort}
                          onUpdatePage={onUpdatePage}
                          isLoading={isLoading}
                          head={[
                            {
                              label: localizedId,
                              sort: 'id',
                            },
                            {
                              label: localizedName,
                              sort: 'name',
                            },
                          ]}
                        >
                          {
                            dataList.map(brand => (
                              <tr key={`${brand.id}_${brand.name}`}>
                                <td>
                                  {brand.id}
                                </td>
                                <td>
                                  {brand.name}
                                </td>
                              </tr>
                            ))
                          }
                        </TableList>
                      )}
                    </LocalizedMessage>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default URLFiltersHoc(Brands, defaultSort);
