import { AllowedMediaStatus, IUserRepresentation, MediaType } from 'types';

const isTvOnlyAllowed = (medias: MediaType[]) => medias.includes(MediaType.TV) && !medias.includes(MediaType.INTERNET);

const isInternetOnlyAllowed = (medias: MediaType[]) => medias.includes(MediaType.INTERNET) && !medias.includes(MediaType.TV);

const isAllMediaAllowed = (medias: MediaType[]) => medias.includes(MediaType.TV) && medias.includes(MediaType.INTERNET);

export const convertProfile = (user: IUserRepresentation) => {
  const { allowedMedias: medias } = user;
  let allowedMediaStatus: AllowedMediaStatus = 'none';

  if (isAllMediaAllowed(medias)) {
    allowedMediaStatus = 'all';
  } else if (isTvOnlyAllowed(medias)) {
    allowedMediaStatus = 'tv';
  } else if (isInternetOnlyAllowed(medias)) {
    allowedMediaStatus = 'internet';
  }

  return { ...user, allowedMediaStatus };
}
