import FilterItems, { IProcessedFilterItem } from 'helpers/filterItems';
import Client from 'api/release/HTTPClient';
import { AnnualBudgetUpdateRequest, IAnnualBudget, IGenericListRequest, IServerPage } from 'types';
import AnnualBudget from 'routes/AnnualBudget';

const annualBudgetsAPI = {
  list (request: IGenericListRequest): Promise<IServerPage<IAnnualBudget>> {
    return Client.post('/api/annualBudgets/', request);
  },

  getForAdvertiserPeriod (): Promise<AnnualBudget> {
    return Client.get('/api/annualBudgets/getForClientPeriod');
  },

  get (id: number): Promise<AnnualBudget> {
    return Client.get(`/api/annualBudgets/${id}`);
  },

  async getFilterItems (request): Promise<IProcessedFilterItem[]> {
    const result = await Client.get('/api/annualBudgets/filter-items', request);

    return FilterItems.getDataAndPostProcess(result);
  },

  save (id: number, data: AnnualBudgetUpdateRequest): Promise<AnnualBudget> {
    if (!id) {
      return Client.post('/api/annualBudgets/new', data);
    }

    return Client.put(`/api/annualBudgets/${id}`, data);
  }
};

export default annualBudgetsAPI;
