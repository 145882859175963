import { connect } from 'react-redux';
import { checkRoles } from 'modules/auth';
import AnnualPlans from '../components/AnnualPlans';

const mapDispatchToProps = {
  checkRoles,
};

const mapStateToProps = (state) => ({
  user: state.auth.profile,
});

export default connect(mapStateToProps, mapDispatchToProps)(AnnualPlans);
