import React from 'react';
import LocalizedMessage from 'components/LocalizedMessage';
import LangSwitcher from 'components/LangSwitcher';
import { IProfile } from 'types';
import classes from './Header.module.scss';

interface IProps {
  isAuthorized: boolean;
  profile: IProfile;
  switchMenu: () => void;
  impersonateLogOut: () => void;
  logOut: () => void;
}

const Header = (props: IProps) => {
  const { isAuthorized, profile, switchMenu, impersonateLogOut, logOut } = props;

  if (!isAuthorized) {
    return null;
  }

  return (
    <div className={classes.Container}>
      <span
        className='navbar-minimalize minimalize-styl-2 btn btn-primary'
        onClick={switchMenu}
        data-test='sidebar-minimalize'
      >
        <i className='fa fa-bars' />
      </span>
      <span className='agency-name'>
        {profile.spaceName}
      </span>
      {' '}
      {profile.impersonate && (
        <button
          className='btn btn-primary ng-scope'
          style={{ marginLeft: '20px', marginTop: '-12px' }}
          onClick={impersonateLogOut}
        >
          <LocalizedMessage
            id='header.backToSuperAdmin'
          />
        </button>
      )}
      <ul className='nav navbar-top-links navbar-right'>
        <li>
          <div className={classes.Profile}>
            {profile.firstName}
            {' '}
            {profile.lastName}
            {' '}
            <em>{`(${profile.role.nameTranslation})`}</em>
          </div>
        </li>
        {' '}
        <li>
          <LangSwitcher />
        </li>
        {' '}
        <li>
          <button
            className='pseudo-link'
            onClick={logOut}
            data-test='logout'
          >
            <i className='fa fa-sign-out' />
            <LocalizedMessage
              id='logout'
            />
          </button>
        </li>
        {' '}
        <li>
          <a href='mailto:support@aizek.io?subject=Appeal%20in%20support'>
            <i className='fa fa-question' />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Header;
