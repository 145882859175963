import Config from 'config';
import Client from 'api/release/HTTPClient';
import { IRestResponse, IUserRepresentation } from 'types';
import { convertProfile } from './converters/convertProfile';

const AuthAPI = {
  logIn (mail: string, password: string): Promise<IRestResponse> {
    return Client.post(`${Config.api.authHost}/api/auth/login`, {
      mail,
      password,
    }, false, { isJson: true }, false);
  },

  impersonateLogIn (username: string) {
    return Client.get(`${Config.api.authHost}/api/auth/login/impersonate`, {
      username,
    }, false, { isJson: false }, false, null, {
      mode: 'no-cors',
    });
  },

  async getProfile (): Promise<IUserRepresentation> {
    const response = await Client.get(`${Config.api.authHost}/api/auth/logged-in`, {}, false, { isJson: false });

    if (!response?.allowedMedias) {
      return response;
    }

    return convertProfile(response);
  },

  impersonateLogOut () {
    return Client.get(`${Config.api.authHost}/api/auth/logout/impersonate`, {}, false, { isJson: false }, false, null, {
      mode: 'no-cors',
    });
  },

  logOut () {
    return Client.get(`${Config.api.authHost}/api/auth/logout`, {}, false, { isJson: false }, false, null, {
      mode: 'no-cors',
    });
  },
};

export default AuthAPI;
