import React, { PureComponent } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import Switch from 'react-switchery';
import LocalizedMessage, { localizeMessage } from 'components/LocalizedMessage';
import URLFiltersHoc, { Props } from 'components/URLFiltersHoc';
import Breadcrumbs from 'components/Breadcrumbs';
import FilterList from 'components/FilterList';
import TableList, { HeadItem } from 'components/TableList';

import Alert from 'helpers/alert';
import API from 'api';

import './Users.scss';

interface IState {
  filterItems: any[],
  dataList: any[],
  dataListTotal: number;
  isLoading: boolean;
}

interface IOwnProps {
  profile: any;
  checkRoles: (role: string) => boolean;
  impersonateLogIn: (m: string) => void;
}

class Users extends PureComponent<Props & IOwnProps, IState> {
  state: IState = {
    filterItems: [],
    dataList: [],
    dataListTotal: 1,
    isLoading: false,
  };

  mounted = false;
  refreshListTimer = 0;
  refreshListTimerDelay = 500;
  showDisabledUsers = false;

  componentDidMount () {
    this.mounted = true;
    this.refreshFilterItems();
    this.refreshList();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.filters !== this.props.filters) {
      this.refreshList();
    }
  }

  componentWillUnmount () {
    this.mounted = false;
    clearTimeout(this.refreshListTimer);
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.profile.role.name !== this.props.profile.role.name) {
      this.refreshList();
    }
  }

  async refreshFilterItems () {
    const filterItems = await API.users.getFilterItems({
      showDisabledUsers: this.showDisabledUsers,
    });

    if (!this.mounted) {
      return;
    }

    this.setState({
      filterItems,
    });
  }

  onImpersonateLogin = (e, mail) => {
    e.preventDefault();

    const { impersonateLogIn } = this.props;

    impersonateLogIn(mail);
  };

  refreshList () {
    clearTimeout(this.refreshListTimer);
    const { currentPage, currentSort, max, items } = this.props.filters;
    const dataListRequest = {
      page: currentPage,
      order: currentSort,
      max,
      filter: { items },
      showDisabledUsers: this.showDisabledUsers,
    };

    this.refreshListTimer = window.setTimeout(async () => {
      this.setState({ isLoading: true });

      const dataList = await API.users.list(dataListRequest);

      if (!this.mounted) {
        return;
      }

      this.setState({
        dataList: dataList.items,
        dataListTotal: dataList.total,
        isLoading: false,
      });
    }, this.refreshListTimerDelay);
  }

  onChangeDisabledUsers = (showDisabledUsers) => {
    this.showDisabledUsers = showDisabledUsers;
    this.filterDisabledUsers();
  };

  filterDisabledUsers () {
    this.refreshList();

    this.refreshFilterItems();
  }

  getBreadcrumbs () {
    return [
      {
        title: <LocalizedMessage id='home' />,
        link: '/app',
      },
      {
        title: <LocalizedMessage id='users' />,
        link: '/app/settings/users',
      },
    ];
  }

  getHeadItems () {
    const {
      checkRoles,
    } = this.props;

    let headItems: HeadItem[] = [
      {
        empty: true,
      },
      {
        label: localizeMessage({ id: 'name' }),
        sort: 'name',
      },
      {
        label: localizeMessage({ id: 'role' }),
        sort: 'role',
      },
      {
        label: localizeMessage({ id: 'email' }),
        sort: 'mail',
      },
    ];

    const isSuperAdmin = checkRoles('SUPER_ADMIN');

    if (isSuperAdmin) {
      headItems.push({
        label: localizeMessage({ id: 'agency' }),
        sort: 'agency_id',
      });
    }

    headItems = [...headItems, ...[
      {
        label: localizeMessage({ id: 'createdAt' }),
        sort: 'created_at',
      },
      {
        label: localizeMessage({ id: 'lastLoginAt' }),
        sort: 'last_login_at',
      },
      {
        empty: true,
        props: {
          className: 'actions',
        },
      },
    ]];

    if (isSuperAdmin) {
      headItems.push({
        empty: true,
      });
    }

    return headItems;
  }

  getUserName (user) {
    if (!user) {
      return '';
    }

    const names: string[] = [];

    if (user.firstName) {
      names.push(user.firstName);
    }

    if (user.lastName) {
      names.push(user.lastName);
    }

    return !names.length ? user.mail : names.join(' ');
  }

  async enableUser (user) {
    const { dataList } = this.state;

    try {
      await API.users.enable(user.id);

      Alert.success(localizeMessage({ id: 'settings.users.user-successfully-enabled' }));

      if (!this.mounted) {
        return;
      }

      const userIndex = dataList.findIndex(_user => _user.id === user.id);

      if (userIndex !== -1) {
        dataList[userIndex].disabled = false;
      }

      this.setState({
        dataList,
      });
    } catch (error) {
      console.error(error);

      Alert.error(localizeMessage({ id: 'settings.users.user-enable-failed' }));
    }
  }

  async disableUser (user) {
    const { dataList } = this.state;

    try {
      await API.users.disable(user.id);

      Alert.success(localizeMessage({ id: 'settings.users.user-successfully-disabled' }));

      if (!this.mounted) {
        return;
      }

      const userIndex = dataList.findIndex(_user => _user.id === user.id);

      if (userIndex !== -1) {
        dataList[userIndex].disabled = true;
      }

      this.setState({
        dataList,
      });
      this.filterDisabledUsers();
    } catch (error) {
      console.error(error);

      Alert.error(localizeMessage({ id: 'settings.users.user-disable-failed' }));
    }
  }

  render () {
    const {
      checkRoles,
      onUpdateSort, onUpdatePage, filters, onChangeFilterItems,
    } = this.props;

    const {
      filterItems,
      dataListTotal,
      dataList,
      isLoading,
    } = this.state;
    const isSuperAdmin = checkRoles('SUPER_ADMIN');

    return (
      <>
        <LocalizedMessage
          id={[
            'site.title.settings.users',
            'site.title.settings.users.add',
          ]}
        >
          {(localizedUsersMessage, localizedAddUserMessage) => (
            <>
              <Helmet
                title={localizedUsersMessage}
              />
              <Breadcrumbs
                title={localizedUsersMessage}
                data={this.getBreadcrumbs()}
                className='col-lg-8'
              >
                <div className='col-lg-4'>
                  <div className='title-action'>
                    {
                      checkRoles('SUPER_ADMIN,ADMIN') && (
                        <Link
                          className='btn btn-warning'
                          to='/app/settings/users/create'
                        >
                          <i className='fa fa-plus fa-lg' />
                          {localizedAddUserMessage}
                        </Link>
                      )
                    }
                  </div>
                </div>
              </Breadcrumbs>
            </>
          )}
        </LocalizedMessage>

        <div className='wrapper wrapper-content'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='ibox float-e-margins'>
                <div className='ibox-content'>
                  <div className='row'>
                    <div className='col-lg-7 m-b-sm'>
                      <div className='flex-grow-1 m-r'>
                        <FilterList
                          placeholder={<LocalizedMessage id='settings.users.filter.placeholder' />}
                          options={filterItems}
                          onChange={onChangeFilterItems}
                          value={filters.items}
                        />
                      </div>
                    </div>
                    <div className='col-lg-5 m-b-sm text-right'>
                      <label className='_cursor--pointer'>
                        <Switch
                          className='js-switch'
                          onChange={this.onChangeDisabledUsers}
                          options={{
                            color: '#1AB394',
                          }}
                        />
                        &nbsp;&nbsp;
                        <LocalizedMessage id='settings.users.show-disabled-users' />
                      </label>
                    </div>
                  </div>

                  <div className='table-responsive'>
                    <TableList
                      currentPage={filters.currentPage}
                      currentSort={filters.currentSort}
                      totalItems={dataListTotal}
                      itemsCountPerPage={filters.max}
                      onUpdateSort={onUpdateSort}
                      onUpdatePage={onUpdatePage}
                      isLoading={isLoading}
                      head={this.getHeadItems()}
                    >
                      {
                        dataList.map(row => (
                          <tr key={`${row.id}_${row.title}`}>
                            <td
                              className='_disabled'
                            >
                              <i
                                className={cx('fa fa-fw', {
                                  'fa-ban': row.disabled,
                                })}
                              />
                            </td>
                            <td>
                              <Link
                                to={`/app/settings/users/${row.id}/edit`}
                              >
                                {this.getUserName(row)}
                              </Link>
                            </td>
                            <td>
                              <span>{row.role ? row.role.nameTranslation : ''}</span>
                            </td>
                            <td>
                              <Link
                                to={`/app/settings/users/${row.id}/edit`}
                              >
                                {row.mail}
                              </Link>
                            </td>
                            {
                              isSuperAdmin ? (
                                <td>{row.extend && row.extend.agency ? row.extend.agency.name : ''}</td>
                              ) : null
                            }
                            <td>
                              {moment(row.createdAt).format('DD/MM/YY')}
                            </td>
                            <td>
                              {moment(row.lastLoginAt).format('DD/MM/YY HH:mm')}
                            </td>
                            <td>
                              {
                                row.disabled ? (
                                  <button
                                    className='btn btn-default btn-xs'
                                    onClick={() => this.enableUser(row)}
                                  >
                                    <i className='fa fa-chevron-down' />
                                    <span><LocalizedMessage id='settings.users.enable' /></span>
                                  </button>
                                ) : (
                                  <button
                                    className='btn btn-default btn-xs'
                                    onClick={() => this.disableUser(row)}
                                  >
                                    <i className='fa fa-ban' />
                                    <span><LocalizedMessage id='settings.users.disable' /></span>
                                  </button>
                                )
                              }
                            </td>
                            <td className='text-right text-nowrap'>
                              {
                                isSuperAdmin ? (
                                  <button
                                    onClick={e => this.onImpersonateLogin(e, row.mail)}
                                    className='btn btn-default btn-xs'
                                  >
                                    <i className='fa fa-sign-in' />
                                    <span><LocalizedMessage id='settings.users.signInAsUser' /></span>
                                  </button>
                                ) : null
                              }
                            </td>
                          </tr>
                        ))
                      }
                    </TableList>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default URLFiltersHoc(Users);
